<template>
  <!-- BEGIN #content -->
  <div id="content" class="app-content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'dashboard' }">PAGES</b-breadcrumb-item>
      <b-breadcrumb-item active>SEARCH RESULTS</b-breadcrumb-item>
    </b-breadcrumb>

    <h1 class="page-header">Search Results</h1>

    <ais-instant-search :search-client="searchClient" :index-name="mainIndex">
      <!-- BEGIN search-result -->
      <div class="search-result">
        <!-- BEGIN search-input -->
        <ais-search-box>
          <div class="search-input" slot-scope="{ refine }">
            <form name="search_form">
              <b-link href="" class="search-close">&times;</b-link>
              <b-input
                v-model="query"
                type="search"
                size="lg"
                :autofocus="Boolean(query)"
                @input="refine(query)"
                @focus="refine(query)"
              />
            </form>
          </div>
        </ais-search-box>
        <!-- END search-input -->

        <!-- BEGIN search-tab -->
        <div class="search-tab">
          <div v-for="(index, i) in indices" :key="i" class="search-tab-item">
            <b-link href="#" :class="['search-tab-link', { active: i === currentTab }]" @click="handleTabChange(i)">
              {{ index.title }}
            </b-link>
          </div>
        </div>
        <!-- END search-tab -->

        <b-tabs v-model="currentTab" nav-class="invisible">
          <b-tab v-for="(index, i) in indices" :key="i" :title="index.title">
            <ais-index :index-name="index.name">
              <ais-hits>
                <!-- BEGIN search-result -->
                <div class="search-result" slot-scope="{ items }">
                  <!-- BEGIN search-result-list -->
                  <div class="search-result-list" v-if="items.length > 0">
                    <div class="search-result-item" v-for="item in items" :key="item.id">
                      <div class="search-result-content">
                        <h3>
                          <b-link :to="parseItem(item, index.name).url">
                            {{ parseItem(item, index.name).title }}
                          </b-link>
                        </h3>
                        <p v-if="parseItem(item, index.name).desc">
                          {{ parseItem(item, index.name).desc }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="search-result-list" v-else>
                    <div class="search-result-item">
                      No record found
                    </div>
                  </div>
                  <!-- END search-result-list -->
                </div>
                <!-- END search-result -->
              </ais-hits>
            </ais-index>
          </b-tab>
        </b-tabs>

        <ais-pagination class="mt-4" />
      </div>
      <!-- END search-result -->
    </ais-instant-search>
  </div>
  <!-- END #content -->
</template>

<script>
import { configureSearchClient } from '../page-helpers'
import 'instantsearch.css/themes/algolia-min.css'

export default {
  name: 'SearchPage',
  data() {
    return {
      query: this.$route.query.query,
      searchClient: configureSearchClient(
        process.env.VUE_APP_ALGOLIA_ID,
        process.env.VUE_APP_ALGOLIA_SEARCH_KEY,
        process.env.VUE_APP_ALGOLIA_LOCAL_READ_API
      ),
      mainIndex: process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX,
      currentTab: 0,
    }
  },
  computed: {
    indices() {
      return [
        { title: 'Products', name: this.mainIndex },
        {
          title: 'Suppliers',
          name: process.env.VUE_APP_ALGOLIA_SUPPLIERS_INDEX,
        },
        { title: 'Users', name: process.env.VUE_APP_ALGOLIA_USERS_INDEX },
      ]
    },
  },
  methods: {
    handleTabChange(index) {
      this.currentTab = index
    },
    parseItem(item, index) {
      const map = {
        [process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX]: item => ({
          title: item.name,
          desc: item.content,
          url: { name: 'products.edit', params: { id: item.id } },
        }),
        [process.env.VUE_APP_ALGOLIA_SUPPLIERS_INDEX]: item => ({
          title: item.booking_name,
          url: { name: 'suppliers.edit', params: { id: item.id } },
        }),
        [process.env.VUE_APP_ALGOLIA_USERS_INDEX]: item => ({
          title: item.full_name,
          url: { name: 'users', query: { query: item.full_name } },
        }),
      }

      return map[index](item)
    },
  },
}
</script>
